$(document).ready(function(){
	const browser=browserCheck();
	const os=osCheck();
	const device=deviceCheck();
	$("html").addClass(browser).addClass(os);


  // <a href="#***">の場合、スクロール処理を追加
  $('a[href*="#"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
  		let $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name="' + this.hash.slice(1) +'"]');
      if ($target.length) {
      	const targetOffset = $target.offset().top;
        $('html,body').animate({ scrollTop: targetOffset }, 800, 'easeOutExpo');//fast
        //$('html,body').animate({ scrollTop: targetOffset }, 1000, 'easeInOutQuart');//smooth
        /*$('html, body').animate({scrollTop: targetOffset/5,opacity: 0}, 300, function() {
					$('html, body').animate({opacity: 1,scrollTop: targetOffset}, 600);
				});//fade scroll*/
        return false;
      }
    }
  });
  
  //Menu
  $("#menu-btn").click(function(){
	  $("#menu").toggleClass("open");
	  $("body").toggleClass("menu-open");
  });
  
  
  //inView
  $('.anm').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('is-show');
    } else {
    //表示領域から出た時
     	//$(this).removeClass('is-show');
    }
  });

  
  //scroll
  $(window).scroll(function () {
   	const body = window.document.body;
	 	const html = window.document.documentElement;
	 	const scrTop = body.scrollTop || html.scrollTop;
	 	const scrBottom = html.scrollHeight - html.clientHeight - scrTop;
	 	if(scrTop > 300){
			$(".pagetop").addClass("is-show");
		}else{
			$(".pagetop").removeClass("is-show");
		}
  });
  
	//exValidation
	if($(".validationform").length){
		/*inputの下にエラー*/
		const validation = $(".validationform").exValidation({
			errInsertPos :"after",
			errPosition :"fixed",
		  stepValidation: true,
		  errHoverHide:false,
		  errTipCloseBtn:false,
		  scrollAdjust:0,
		  errMsgPrefix : ""
		});
		
		//アンケート表示・非表示
		/*const type=$('[name="type"]:checked').val();
		if(type=="ダウンロードを希望する"){
			$(".question-box").removeClass("hide");
		}else{
			$(".question-box").addClass("hide");
		}
		$('[name="type"]').on("click",function(){
			const type=$('[name="type"]:checked').val();
			if(type=="ダウンロードを希望する"){
				$(".question-box").removeClass("hide");
			}else{
				$(".question-box").addClass("hide");
			}	
		});*/
	}
});

//画面サイズごとの処理
$(window).on('load resize', function(){
	const w = $(window).width();
	deviceFunc(w);
});

function deviceFunc(w){
	const bp = [599,960,1280];
	if (w <= bp[0]) {
		//sp
		$(".tel-number").each(function(){
			if(!$(this).parent(".tel-wrap").length){
				const no = $(this).data("tel");
				$(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
			}
		});
  } else if(w <= bp[1]){
		//tb
		$(".tel-wrap .tel-number").each(function(){
			$(this).unwrap();
		});
  } else if(w <= bp[2]){
		//small pc
  } else{
  
  }
}

/*ブラウザ判別*/
function browserCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	const av = window.navigator.appVersion.toLowerCase();
	if(ua.indexOf('edge') != -1){
		return 'edge';
	}else if (ua.indexOf('opera') != -1) {
  	return 'opera';
	}else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
		$("body").addClass('ie');
  	if (av.indexOf("msie 6.") != -1) {
    	return 'ie6';
  	} else if (av.indexOf("msie 7.") != -1) {
    	return 'ie7';
  	} else if (av.indexOf("msie 8.") != -1) {
    	return 'ie8';
  	} else if (av.indexOf("msie 9.") != -1) {
    	return 'ie9';
  	}else if (av.indexOf("msie 10.") != -1) {
    	return 'ie10';
  	}else if (av.indexOf("rv:11.") != -1) {
    	return 'ie11';
  	}
	} else if (ua.indexOf('chrome') != -1) {
  	return 'chrome';
	} else if (ua.indexOf('safari') != -1) {
  	return 'safari';
	} else if (ua.indexOf('gecko') != -1) {
  	return 'firefox';
	} else {
  	return false;
	}
}

/*OS判別*/
function osCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if (ua.indexOf('windows') != -1) {
		if(ua.indexOf('phone') != -1){
			return 'winphone';
		}else{
			return 'win';
		}
	}else if(ua.indexOf('iphone') != -1) {
		return 'iphone';
	}else if(ua.indexOf('ipad') != -1) {
		return 'ipad';
	}else if(ua.indexOf('mac') != -1) {
		return 'mac';
	}else if(ua.indexOf('android') != -1) {
		if(ua.indexOf('mobile') != -1){
			if(ua.indexOf('A1_07') != -1){
				return 'tablet';
			}else if(ua.indexOf('SC-01C') != -1){
				return 'tablet';
			}else{	return 'android';}
		}else{
			return 'tablet';
		}
	}else{
		return false;
	}
}

/*デバイス判別*/
function deviceCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if((ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) 
      || ua.indexOf("ipad") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
      || ua.indexOf("kindle") != -1
      || ua.indexOf("silk") != -1
      || ua.indexOf("playbook") != -1){
      return 'tab';
    }else if((ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
      || ua.indexOf("iphone") != -1
      || ua.indexOf("ipod") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
      || ua.indexOf("blackberry") != -1){
	    return 'sp';
    }else{
      return 'pc';
    }
}